import React from 'react';

import { TelegramNewsItem } from './TelegramNewsItem';
import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import { Button, EButtonSize, TButtonProps } from '../../atoms/Button';
import { Container, EContainerVariant } from '../../sections/Container';

export type TTelegramNewsPost = {
  title?: string;
  postID?: number;
  telegramUserName?: string;
};

type TTelegramNewsProps = {
  posts: TTelegramNewsPost[];
  isLoading: boolean;
  pinedPost?: TTelegramNewsPost;
  button?: TButtonProps;
};

export const TelegramNews: React.FC<TTelegramNewsProps> = ({
  posts,
  isLoading,
  pinedPost,
  button,
}) => {
  return (
    <Container variant={EContainerVariant.Full}>
      {isLoading ? (
        <div className='h-full w-full bg-surface-100'>
          <LoaderImg className='h-[435px] w-full' />
        </div>
      ) : pinedPost || posts.length > 0 || button ? (
        <div className='flex flex-col'>
          {pinedPost || posts.length > 0 ? (
            <div className='-mx-4 flex min-h-[435px] flex-row flex-nowrap gap-2 overflow-x-auto px-4 pb-4 md:gap-4 2xl:pb-0'>
              {pinedPost && <TelegramNewsItem {...pinedPost} />}
              {posts.map((post, index) => (
                <TelegramNewsItem {...post} key={index} />
              ))}
            </div>
          ) : null}
          {button ? (
            <div className='mx-auto flex flex-row flex-wrap items-start gap-2.5'>
              <Button
                {...button}
                size={EButtonSize.Small}
                className='mt-4 h-[55px] min-w-[192px] rounded-4xl bg-[#5EB5F7] leading-normal md:mt-8'
              >
                {button.children}
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </Container>
  );
};
