import { buildTelegramPostsList, TelegramNews } from '@front/shared/ds';
import { Section, TelegramPost } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import useTelegramPosts from '../../../hooks/useTelegramPosts';
import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TTelegramNewsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'telegram-news' }
>;

const TelegramNewsConnected: React.FC<TTelegramNewsConnectedProps> = props => {
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');
  const pinedPost = props.pinedPost as TelegramPost;
  const pinedPostID = pinedPost?.id;
  const perPage = pinedPostID ? 2 : 3;
  const button = toCardButtons(props.action, currentLocale, appDispatch)?.[0];

  const { isLoading, docsArray } = useTelegramPosts({
    page: 0,
    perPage: perPage,
    excludePostID: pinedPostID,
  });

  const posts = buildTelegramPostsList(docsArray).map(post => ({
    ...post,
  }));

  const modifiedPinedPost = pinedPost
    ? {
        ...pinedPost,
      }
    : undefined;

  return (
    <TelegramNews
      button={button}
      isLoading={isLoading}
      pinedPost={modifiedPinedPost}
      posts={posts}
    />
  );
};

export default TelegramNewsConnected;
