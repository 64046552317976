import React, { useEffect, useRef } from 'react';

import { TTelegramNewsPost } from './TelegramNews';

export const TelegramNewsItem: React.FC<TTelegramNewsPost> = ({
  postID,
  telegramUserName,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scriptLoadedRef = useRef(false);
  const telegramWidgetScriptURL = 'https://telegram.org/js/telegram-widget.js';

  useEffect(() => {
    const loadScript = (): void => {
      if (!postID || !telegramUserName || scriptLoadedRef.current) {
        return;
      }

      const script = document.createElement('script');
      script.async = true;
      script.src = telegramWidgetScriptURL;
      script.setAttribute('data-userpic', 'false');
      script.setAttribute(
        'data-telegram-post',
        `${telegramUserName}/${postID}`,
      );
      script.setAttribute('data-width', '100%');

      if (containerRef.current) {
        containerRef.current.appendChild(script);
        scriptLoadedRef.current = true;
      }
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadScript();
          observer.unobserve(entry.target);
        }
      });
    });

    const currentContainer = containerRef.current;

    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, [postID, telegramUserName]);

  if (!postID || !telegramUserName) {
    return null;
  }

  return (
    <div className='relative flex min-w-[343px] basis-[calc(100%/3-16px/3*2)] flex-col gap-6'>
      <a
        href={`https://t.me/${telegramUserName}/${postID}`}
        target='_blank'
        rel='noopener noreferrer'
        className='absolute end-2 start-auto top-3 h-6 w-10 overflow-hidden bg-brand-500 opacity-0'
      >
        {telegramUserName}
      </a>
      <div className='flex h-full flex-col gap-6' ref={containerRef} />
    </div>
  );
};
